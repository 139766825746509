$(document).ready(function () {

    $(document).foundation();

    $(function () {

        // PUBLIC  -----------------------------
        // -----------------------------

        $("#disclaimer-checkbox").change(function () {
            if (this.checked) {
                //Do stuff
                //$('.btn-submit').prop("disabled", false); // Element(s) are now enabled.
                $('.false-button').hide();
                $('.main-button').show();
            } else {

                $('.false-button').show();
                $('.main-button').hide();

            }
        });

        $('.js-btn-submit-disabled').on("click", function (e) {
            e.preventDefault();
            $(".js-group-disclaimer-checkbox").fadeIn(300).fadeOut(300).fadeIn(300).fadeOut(300).fadeIn(300).fadeOut(300).fadeIn(300);
        });


        // VRAGENLIJST
        $('.js-questions-show-info').on("click", function () {

            $(this).parent().parent().find(".info").slideDown("slow", function () {
                // Animation complete.
            });
            $(this).hide();
        });

        $('.js-answers-show-info').on("click", function () {

           // console.log('answers')
            $(this).parent().parent().find(".answers-info").slideDown("slow", function () {
                // Animation complete.
            });
            $(this).hide();

            //because this is inside the label undo the action
            checkparent = $(this).parent().parent().find('input');
            if (checkparent.prop('checked') == true) {
                $(checkparent).prop("checked", false);
            } else if (checkparent.prop('checked') == false) {
                $(checkparent).prop("checked", true);
            }


        });

    });

});

//http://jsfiddle.net/88Ms2/378/
var videoEmbed = {
    invoke: function ($el) {
        $el.html(function (i, html) {
          return videoEmbed.convertMedia(html);
        });
    },
    convertMedia: function (html) {
        html = $.trim(html);
        var pattern1 = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g;
        var pattern2 = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g;

        var replacement;


        if (pattern1.test(html)) {
            replacement = '<iframe width="420" height="345" src="//player.vimeo.com/video/$1" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
            html = html.replace(pattern1, replacement);
        }else if (pattern2.test(html)) {
            replacement = '<iframe width="420" height="345" src="http://www.youtube.com/embed/$1" frameborder="0" allowfullscreen></iframe>';
            html = html.replace(pattern2, replacement);
        }

        return html;
    }
};
