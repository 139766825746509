/** invite.js **/
// WERKGEVERS INVITE  -----------------------------
// -----------------------------

var invite = {
  init: function (settings) {

    invite.config = {
      $form: $('#js-invitemail'),
      $formUpload: $('#js-excelupload'),
      $addMailFieldButton: $('.js-add-mailfield'),
      $deleteMailButton: $('.js-delete-contact'),
      mailFieldTemplate: '<div class="row email"><div class="small-2 columns"><label class="inline"></label></div><div class="small-10 columns"><input type="email" placeholder="e-mail" name="email[]" class="required"><a href="#" class="alert remove-contact js-delete-contact"></a></div></div>',
      $emailContainer: $('#email-fields'),
      $errorsContainer: $('#errors'),
      $submitButton: $('.js-submitInvite'),
      $inviteLinkActive: $('.js-invitelink-active'),
      panel1: {
        $element: $('#panel1'),
        $tab: $('.panel1tab')
      },
      panel2: {
        $element: $('#panel2'),
        $tab: $('.panel2tab')
      },
      $uploadModal: $('#upload-modal'),
      $resendModal: $('#resend-modal'),
      $deleteInviteModal: $('#delete-invite-modal'),
      $reinviteButton: $('.js-reinvite'),
      $deleteinviteButton: $('.js-deleteinvite'),
      $submitResendButton: $('.js-submit-resend'),
      $submitInviteDelete: $('.js-submit-invitedelete'),
      $closeResendModalButton: $('.js-close-resend'),
      $previousScanButton: $('.js-previous-scan'),
      scanId: 1,


    };

    // Allow overriding the default config
    $.extend(invite.config, settings);

    this.bindEvents();

    if (this.config.$form) {
      this.config.$form.validate();
    }

    if (this.config.$formUpload) {
      this.config.$formUpload.ajaxForm({

        url: '../werkgevers/postWerknemersuitnodigen',
        type: 'post',
        // dataType identifies the expected content type of the server response
        dataType: 'json',

        // success identifies the function to invoke when the server response
        // has been received
        success: invite.handleJsonSucces,
        error: invite.handleJsonError

      });
    }

    /* delete button */
    $("body").on({
      click: function () {
        $(this).parent().parent().remove();
      }
    }, ".js-delete-contact");


  },

  bindEvents: function () {

    this.config.$addMailFieldButton.on('click', function (e) {
      e.preventDefault();
      invite.config.$emailContainer.append(invite.config.mailFieldTemplate);
    });

    //this.config.$submitButton.click(function () {
    //    //alert('Medewerkers worden nu uitgenodigd. Het kan enkele minuten duren vooraleer de mail en invitatielink aankomt bij de medewerker. Wanneer de link niet aankomt probeer de spamfilter of stuur invitatielink opnieuw.');
    //    invite.config.$form.submit();
    //});

    this.config.$inviteLinkActive.on("click", function (e) {
      e.preventDefault();

      invite.config.panel1.$element.removeClass('active');
      invite.config.panel2.$element.addClass('active');
      invite.config.panel1.$tab.removeClass('active');
      invite.config.panel2.$tab.addClass('active');
    });

    // TAB 2 WERKNEMERS OPNIEW UITNODIGEN  AJAX
    this.config.$reinviteButton.on("click", function (e) {
      e.preventDefault();

      var $modal = invite.config.$resendModal;
      $modal.find('.recipient').text($(this).data('email'));
      $modal.foundation('reveal', 'open');

      var $submitResendButton = invite.config.$submitResendButton;
      $submitResendButton.attr('data-href', $(this).data('href'));
      $submitResendButton.attr('data-userid', $(this).data('userid'));
    });

    this.config.$deleteinviteButton.on('click', function (e) {
      e.preventDefault();
      const modal = invite.config.$deleteInviteModal;
      const inviteUrl =  $(this).data('href');
      console.log(modal.find('.deleteInviteForm'));

      modal.find('.deleteInviteForm').attr('action', inviteUrl);
      modal.foundation('reveal', 'open');

    });

    this.config.$closeResendModalButton.on("click", function (e) {
      invite.config.$resendModal.foundation('reveal', 'close');
    });

    this.config.$submitResendButton.on('click', function (e) {
      e.preventDefault();

      // link
      var reinviteUrl = $(this).attr('data-href'),
        userid = $(this).attr('data-userid'),
        htmlString = '<span class="label">Werknemer opnieuw uitgenodigd</span>',
        data = {
          'subject': $('input[name="resend-subject"]').val(),
          'message': $('textarea[name="resend-message"]').val()
        };

      // update row
      $.ajax({
        url: reinviteUrl,
        method: "post",
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(data),
        error: function (p1, p2, p3) {
          //console.log(p1, p2, p3);
          alert("ERROR-01. Something went wrong with Ajax. There is an invalid value on the reinvite ajax function. Feel free to report to our support with specifics on timings and browsers.");
        },
        success: function (data) {
          $('#flashmessages').append('<div data-alert class="alert-box success">' + JSON.parse(data).success + '<a href="#" class="close">&times;</a></div>');
        }
      });

      var $row = $('#invites-table tr[data-userid="' + userid + '"]');
      $row.find('.positie').html(htmlString);
      $row.find('.js-reinvite').remove();

      invite.config.$resendModal.foundation('reveal', 'close');

      // prevent the edit td click
      return false;
    });

    this.config.$previousScanButton.on('click', function (e) {
      e.preventDefault();

      $.ajax({
        url: '/werkgevers/ajax/previous-scan-invite-emails',
        method: "get",
        contentType: "application/json; charset=utf-8",
        error: function (p1, p2, p3) {
          console.log(p1, p2, p3);
        },
        success: invite.handleJsonSucces
      });

      return false;
    });


  },

  handleJsonError: function (data, p2, p3) {
    alert('XLS kon niet geïmporteerd worden. Bent u zeker dat deze file juist geformateerd is (zie boven). Eventueel kan het ook handmatig of contacteer support met de file in bijlage.')

  },

  handleJsonSucces: function (data) {
    // 'data' is the json object returned from the server

    if (data.emails !== undefined && data.emails.length > 0) {
      invite.config.$uploadModal.foundation('reveal', 'close');

      // checken of laatste veld leeg is, indien zo mag het weg
      var lastValue = invite.config.$emailContainer.find('div.row:last input').val();
      if (lastValue === "") {
        invite.config.$emailContainer.find('div.row:last').remove();
      }

      // data toevoegen
      data.emails.forEach(function (entry) {
        if (entry !== '') {
          var htmlString = '<div class="row email"><div class="small-2 columns"><label class="inline"></label></div><div class="small-10 columns"><input type="email" placeholder="e-mail" name="email[]" class="required" value="' + entry + '"><a href="#" class="alert remove-contact js-delete-contact"></a></div></div>';
          var htmlStringfirst = '<div class="row email"><div class="small-2 columns"><label class="inline">To</label></div><div class="small-10 columns"><input type="email" placeholder="e-mail" name="email[]" class="required" value="' + entry + '"><a href="#" class="alert remove-contact js-delete-contact"></a></div></div>';

          var rowCount = invite.config.$emailContainer.find('div.row').length;

          if (invite.config.$emailContainer.find('input[value="' + entry + '"]').length === 0) {
            if (rowCount > 0) {
              invite.config.$emailContainer.find('div.row:last').after(htmlString);
            } else {
              invite.config.$emailContainer.html(htmlStringfirst);
            }
          }

        }
      });

    } else {

    }

    if (data.errors !== undefined && data.errors.length > 0) {

      var $container = $('<div />', { class: 'row' });
      var $div = $('<div />', { class: 'small-12 columns' });
      $div.append($('<h6 />').text('Import fouten'));
      var $ul = $('<ul />');


      data.errors.forEach(function (item) {

        console.log('item', item);

        if (item !== undefined) {
          $li = $('<li />').append(i);

          if (item.error !== undefined) {
            $li.append($('<strong>').text('lijn ' + item.line + ': '));

            for (var i in item.error) {

              for (var j in item.error[i]) {
                $li.append(item.error[i][j]);
              }

              $ul.append($li);
            }


          }
        }
      });
      $div.append($ul);
      $container.append($div);
      invite.config.$errorsContainer.removeClass('hide').addClass('alert-box').empty().append($container);
    }
  }


};



